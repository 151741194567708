const NotApplyToSection = () => {
  return (
    <>
      <h2>Försäkringen gäller inte för:</h2>
      <ul>
        <li>
          Sjukdom och besvär eller följd av sådana tillstånd där symtomen visat
          sig före försäkringens startdag.
        </li>
        <li>
          Vård som inte på förhand har godkänts av försäkringsgivaren eller dess
          partners.
        </li>
        <li>
          Akut sjukvård, akuta transporter och kostnader uppkomna i samband med
          akut vård.
        </li>
        <li>
          Förebyggande behandling, underhållsbehandling, vaccinationer,
          hälsoundersökningar och screeningundersökningar.
        </li>
        <li>Behandling av kroniska/varaktiga sjukdomar och besvär.</li>
        <li>
          Anmälningspliktig sjukdom som finns reglerad i lag eller följder av
          sådan sjukdom.
        </li>
        <li>
          Kosmetiska behandlingar och operationer eller följder därav som inte
          är en följd av ersättningsberättigad sjukdom eller olycksfallsskada.
        </li>
        <li>
          Utredning, behandling eller operation relaterad till sterilisering,
          abort, preventivmedel, graviditet, förlossning,
          familjeplanering/ofrivillig barnlöshet eller könskorrigering och
          konsekvenserna av sådan behandling.
        </li>
        <li>
          Utredning och behandling av neuropsykiatrisk störning (t.ex. ADHD,
          autism, bipolär sjukdom).
        </li>
        <li>
          Behandling eller operation relaterad till fetma eller lipödem,
          inklusive dieter, viktkontroll och överviktskirurgi samt
          konsekvenserna av sådan behandling.
        </li>
        <li>
          Utredning och behandling av snarkning och sömnapné samt tillhörande
          medicinsk utrustning.
        </li>
        <li>
          Undersökning och behandling av tänder eller synfel, oavsett orsak.
        </li>
      </ul>
    </>
  );
};

export default NotApplyToSection;
