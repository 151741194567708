import ChangesInInsuranceSectionPart1 from './ChangesInInsuranceSectionPart1';
import PolicyInformationSection from './PolicyInformationSection';

const RenewedPolicyPage1 = ({ data }) => {
  return (
    <>
      <h1>
        Sjukvårdsförsäkring
        <br />
        Försäkringsbesked
        <p>2025</p>
      </h1>
      <PolicyInformationSection data={data} />
      <ChangesInInsuranceSectionPart1 />
    </>
  );
};

export default RenewedPolicyPage1;
