const DeducibleSection = ({ data }) => {
  return (
    <>
      <h2>Självrisk</h2>
      <p>
        {data && (
          <span style={{ backgroundColor: 'white' }}>
            {data.answers.excessLevel === '0'
              ? 'Försäkringen gäller utan självrisk.'
              : 'För försäkringen gäller en självrisk på 500 SEK.'}
          </span>
        )}
      </p>
      <p>
        Självrisken gäller per behandlingsperiod. Självrisken betalar du
        vanligen i samband med Vårdplaneringens hänvisning till första
        undersökningen/behandlingen alternativt vid det första besöket hos
        legitimerad personal.
      </p>
    </>
  );
};

export default DeducibleSection;
