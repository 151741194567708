import styled from 'styled-components';

const ChangesInInsuranceSectionPart1 = () => {
  return (
    <>
      <h2>Förändringar i din sjukvårdsförsäkring</h2>
      <p>
        Ni har nu varit kund hos oss i snart ett år. Vi sänder därmed ut ert nya
        försäkringsbesked för kommande period.
      </p>
      <p>
        Vi har tydliggjort några av våra begränsningar i försäkringen. Vänligen
        se under punkt B.4.2 i försäkringsvillkoren för närmare information.
      </p>
      <Caption>Har ni blivit fler i företaget?</Caption>
      <p>
        Hör av er till oss så ser vi till att uppdatera försäkringen så att alla
        omfattas av försäkringen.
      </p>
      <p>Vi ser fram mot ett nytt försäkringsår tillsammans med er!</p>
    </>
  );
};

export default ChangesInInsuranceSectionPart1;

const Caption = styled.span`
  color: #26dcba;
`;
