const InformationSectionPart2 = () => {
  return (
    <>
      <p>
        Försäkringsgivaren står under tillsyn av Finansinspektionen.
        Försäkringsgivaren finns i Finansinspektionens företagsregister
        registrerat som riskbolag, skadeförsäkringar. Kontaktuppgifter till
        Finansinspektionen är; Box 7821, 103 97 Stockholm,
        finansinspektionen@fi.se, www.fi.se, tel. 08-408 980 00.
      </p>
      <p>
        Försäkringsgivaren och Avonova står även under Konsumentverkets tillsyn
        vad gäller marknadsföring och reklam (Konsumentverket, Box 48, 651 02
        Karlstad, konsumentverket@konsumentverket.se, www.konsumentverket.se,
        tel. 0771-42 33 00).
      </p>
      <p>
        Vidare står Försäkringsgivaren och Avonova under
        Integritetsskyddsmyndighetens tillsyn vad avser behandling av
        personuppgifter (Integritetsskyddsmyndigheten, Box 8114, 104 20
        Stockholm, imy@imy.se).
      </p>
    </>
  );
};

export default InformationSectionPart2;
